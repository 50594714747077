<template>
  <v-app>
    <v-img contain :src="url" />
  </v-app>
</template>

<style>

  /* .maintenance-overlay .maintenance {
    width: 48px;
    height: 48px;
    opacity: .8;
  }
  .maintenance-overlay hr {
    width: 80%;
  } */
  
</style>

<script>

  export default {
    name: 'ImageViewer',

    data: () => ({
      url: null
    }),

    mounted () {
      const route = this.$route;
      this.url = route.query.url;
    }
  }
</script>
